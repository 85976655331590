export const portfolioData = [
  {
    id: 1,
    name: "Projet Pro : Lucie BACON - Écritures (carto)graphiques (php/Wordpress)",
    languages: ["wordpress", "css", "javascript"],
    languagesIcons: ["fab fa-php fa-lg", "fab fa-wordpress"],
    source: "https://luciebacon.com/",
    link: "https://luciebacon.com/",
    picture: "./media/cover-luciebacon.jpg",
  },
  {
    id: 8,
    name: "OpenClassrooms-Transformer une maquette en site web(html, css)",
    languages: ["html", "css"],
    languagesIcons: ["fab fa-html5", "fab fa-css3-alt"],
    source: "https://github.com/leexfe/P2-Booki",
    link: "https://leexfe.github.io/P2-Booki/",
    picture: "./media/project1.jpg",
  },
  {
    id: 2,
    name: "OpenClassrooms-Dynamiser une page web avec des animations css",
    languages: ["html", "css"],
    languagesIcons: ["fab fa-html5", "fab fa-css3-alt"],
    source: "https://github.com/leexfe/ohmyfood",
    link: "https://leexfe.github.io/ohmyfood/",
    picture: "./media/project2.jpg",
  },
  {
    id: 3,
    name: "OpenClassrooms-Optimiser un site web existant (SEO, WCAG)",
    languages: ["javascript", "html", "css"],
    languagesIcons: ["fab fa-html5", "fab fa-css3-alt", "fab fa-js"],
    source: "https://github.com/leexfe/P4-fel-dev-web-version-amelioree",
    link: "https://leexfe.github.io/P4-fel-dev-web-version-amelioree/index.html",
    picture: "./media/project3.jpg",
  },
  {
    id: 4,
    name: "OpenClassrooms-Construire un site e-commerce(JS,css)",
    languages: ["javascript", "html", "css"],
    languagesIcons: ["fab fa-js", "fab fa-html5", "fab fa-css3-alt"],
    source: "https://github.com/leexfe/P5-kanap-front",
    link: "https://github.com/leexfe/P5-kanap-front",
    picture: "./media/project4.jpg",
  },
  {
    id: 5,
    name: "OpenClassrooms-Construire une API sécurisée (nodeJs, Express)",
    languages: ["javascript", "css"],
    languagesIcons: ["fab fa-js", "fab fa-css3-alt"],
    source: "https://github.com/leexfe/p6-api-sauce-piquante",
    link: "https://github.com/leexfe/p6-api-sauce-piquante",
    picture: "./media/project5.jpg",
  },
  {
    id: 6,
    name: "OpenClassrooms-Créer un réseau social d'entreprise (JS, VueJS)",
    languages: ["javascript", "vueJs", "css"],
    languagesIcons: ["fab fa-js", "fab fa-vuejs", "fab fa-bootstrap"],
    source: "https://github.com/leexfe/FelBa-P7-Groupomania-front",
    link: "https://github.com/leexfe/FelBa-P7-Groupomania-back",
    picture: "./media/project6.jpg",
  },
  {
    id: 7,
    name: "Portfolio Félix BACON (JS/React)",
    languages: ["javascript", "react", "css"],
    languagesIcons: ["fab fa-css3-alt","fab fa-js", "fab fa-react"],
    source: "https://felixbacon.online/",
    link: "https://felixbacon.online/",
    picture: "./media/portfolio-felixbacon.jpg",
  },
  {
    id: 9,
    name: "Modèle Démo Site Web",
    languages: [ "html", "css","javascript"],
    languagesIcons: ["fab fa-js", "fab fa-html5", "fab fa-css3-alt"],
    source: "https://leexfe.github.io/modele-demo-explore-spots/",
    link: "https://leexfe.github.io/modele-demo-explore-spots/",
    picture: "./media/project7.jpg",
  },
];
